import React, {ReactNode, useEffect, useState} from 'react';
// CSS
import './App.css';
// MUI
import {
    Avatar,
    Button,
    ButtonBase,
    CircularProgress,
    Container,
    Grid,
    Link,
    Typography
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WebIcon from '@mui/icons-material/Web';
import DownloadIcon from '@mui/icons-material/Download';
// Interfaces
import {AddressInput, AppConfig} from "./ifaces";
// Calls
import {APIGet, APIGetBlob, HttpResponse} from "./fetchBuilder";
// Components
import PaperWithLabel from "./PaperWithLabel";
// Translation
import {useTranslation} from "react-i18next";

const {REACT_APP_RESOLVER_URL} = process.env;

// redirectToResolver404 will redirect the user to our regular 404 page
const redirectToResolver404 = () => {
    console.log("Redirecting to 404");
    // This URL will generate a 404 on the resolver
    // window.location.href = "https://qrcode.link/a/";
}

function App() {
    const {t} = useTranslation(["common"])
    // loading is used in order to display content once AppConfig payload has been fetched
    // it should **always** be present in our code
    const [loading, setLoading] = useState(true);

    // appConfig is used to store the payload received into a var
    // it should **always** be of type <AppConfig | null>
    const [appConfig, setAppConfig] = useState<AppConfig | null>(null);

    const styles = {
        loaderWrapper: {textAlign: "left", paddingTop: "10%"},
        vcardContainer: {pt: 6},
        nameWrapper: {marginTop: "10px"},
        name: {fontSize: 24, fontWeight: 500},
        jobWrapper: {marginTop: "8px"},
        job: {fontSize: '1rem', fontWeight: 300, marginTop: -1},
        addressItem: {paddingLeft: 10},
        link: {paddingLeft: 10, color: "#6192BD"},
        downloadButton: {m: "25px"},
    }

    // Init your app using an initial useEffect in order to fetch our payload
    useEffect(() => {

        // DEBUG: Local development
        // setAppConfig({
        //     first_name: "Vincent",
        //     last_name: "Biret",
        //     emails: {
        //         work: "XXX@email.unitag.io"
        //     },
        //     tels: {
        //         work: "+447482445000",
        //         home: '+33677989896'
        //     },
        //     url: 'https://unitag.io',
        //     addrs: {
        //         general: {
        //             city: 'Brentwood',
        //             pc: 'CM14 5JR',
        //             country: 'United Kingdom',
        //         },
        //         work: {
        //             city: 'London',
        //             pc: 'SW113 TS',
        //             country: 'United Kingdom',
        //         }
        //     },
        //     org: 'Unitag',
        //     job_title: 'CEO'
        // });
        //
        // setLoading(false);
        // return;
        // DEBUG END

        const urlParams = new URLSearchParams(window.location.search);
        const domain = urlParams.get("domain");
        let resAddr = `${REACT_APP_RESOLVER_URL}`;
        if (typeof (domain) === "string" && domain !== "") {
            resAddr = "https://" + domain;
        }
        // Get the ID of the vcard from our window location
        const fragments = window.location.pathname.split("/");
        const id = fragments[fragments.length - 1];
        const type = fragments[fragments.length - 2];
        // Call resolver in the format
        // resolver endpoint + app specific prefix + id

        APIGet<AppConfig>(`${resAddr}/${type}/${id}`).then((data) => {
            // If the data is well parsed then set state to response payload
            if (data.parsedBody !== undefined) {
                setAppConfig(data.parsedBody);
            } else {
                // If an error has occured or payload is empty / corrupted then redirect to resolver 404
                redirectToResolver404();
            }
        }).catch(() => {
            // If an error has occured or payload is empty / corrupted then redirect to resolver 404
            redirectToResolver404();
        }).finally(() => {
            // In both cases the app has finished loading, we can update the state accordingly
            setLoading(false);
        });
    }, []);

    // Downloading
    const download = () => {

        const DownloadBlob = (fileName: string, blob: Blob) => {
            const anchor = window.document.createElement('a');
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = fileName;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(anchor.href);
        }

        const urlParams = new URLSearchParams(window.location.search);
        const domain = urlParams.get("domain");

        let resAddr = `${REACT_APP_RESOLVER_URL}`;

        if (typeof (domain) === "string" && domain !== "") {
            resAddr = "https://" + domain;
        }
        // Get the ID of the vcard from our window location
        const fragments = window.location.pathname.split("/");
        const id = fragments[fragments.length - 1];
        const type = fragments[fragments.length - 2];

        APIGetBlob<any>(`${resAddr}/${type}/${id}/download`)
            .then((data: HttpResponse<any>) => {
                if (data.ok && data.rawBlob) {
                    DownloadBlob(data.headers.get("filename") || `${id}.vcf`, data.rawBlob)
                }
            })
            .catch((err: any) => {
                console.log("Err => ", err);
            })
    }

    // Renders
    const renderVcardContent = () => {

        const keyToText = (key: string) => {
            const trans_address: string = t('address'),
                trans_city: string = t('city'),
                trans_country: string = t('country'),
                trans_state: string = t('state'),
                trans_email: string = t('email'),
                trans_email_home: string = t('email_home'),
                trans_email_work: string = t('email_work'),
                trans_phone_mobile: string = t('phone_mobile'),
                trans_phone_work: string = t('phone_work'),
                trans_phone_home: string = t('phone_home'),
                trans_website: string = t('website');

            switch (key) {
                case "addr":
                    return trans_address;
                case "city":
                    return trans_city;
                case "country":
                    return trans_country;
                case "state":
                    return trans_state;
                case "emails.general":
                    return trans_email;
                case "emails.home":
                    return trans_email_home;
                case "emails.work":
                    return trans_email_work;
                case "tels.cell":
                    return trans_phone_mobile;
                case "tels.work":
                    return trans_phone_work;
                case "tels.home":
                    return trans_phone_home;
                case "url":
                    return trans_website;

            }
        }

        // Inner renders
        const createSectionNode = (key: string, value: string) => {
            return (
                <Grid item xs={12}>
                    <PaperWithLabel label={keyToText(key)}>
                        <Typography style={styles.addressItem}>{value}</Typography>
                    </PaperWithLabel>
                </Grid>
            )
        }

        const createEmailSectionNode = (key: string, value: string) => {
            return (
                <Grid item xs={12}>
                    <PaperWithLabel label={keyToText(key)}>
                        <Link href={"mailto:" + value} style={styles.link} underline="hover">
                            {value}
                        </Link>
                    </PaperWithLabel>
                </Grid>
            )
        }

        const createPhoneSection = (key: string, value: string) => {
            return (
                <Grid item xs={12}>
                    <PaperWithLabel label={keyToText(key)}>
                        <Link href={"tel:" + value} style={styles.link} underline="hover">
                            {value}
                        </Link>
                    </PaperWithLabel>
                </Grid>
            )
        }

        const createLinkSectionNode = (key: string, value: string) => {
            return (
                <Grid item xs={12}>
                    <PaperWithLabel label={keyToText(key)}>
                        <Link href={value} style={styles.link} underline="hover">
                            {value}
                        </Link>
                    </PaperWithLabel>
                </Grid>
            )
        }

        // Addresses mapping
        const renderAddresses = () => {

            const renderFormattedAddress = (addr: AddressInput | undefined) => {

                if (addr === undefined || Object.keys(addr).length === 0) return <></>;

                return (
                    <Grid item xs={12}>
                        <PaperWithLabel label={"Address"}>
                            {addr.addr !== undefined && <Typography style={styles.addressItem}>{addr.addr}</Typography>}
                            {addr.city !== undefined && <Typography style={styles.addressItem}>{addr.city}</Typography>}
                            {addr.state !== undefined && <Typography style={styles.addressItem}>{addr.state}</Typography>}
                            {addr.pc !== undefined && <Typography style={styles.addressItem}>{addr.pc}</Typography>}
                            {addr.country !== undefined && <Typography style={styles.addressItem}>{addr.country}</Typography>}
                        </PaperWithLabel>
                    </Grid>
                )

            }

            if (appConfig !== null) {
                return (
                    <>
                        {/*renderFormattedAddress(appConfig.addrs?.general)*/}
                        {renderFormattedAddress(appConfig.addrs?.work)}
                        {renderFormattedAddress(appConfig.addrs?.home)}
                    </>
                )
            }
            return <></>;
        }

        let sections: ReactNode[] = [];

        if (appConfig !== null) {

            if (appConfig.tels !== undefined) {
                if (appConfig.tels.cell !== undefined) {
                    sections.push(createPhoneSection("tels.cell", appConfig.tels.cell))
                }
                if (appConfig.tels.work !== undefined) {
                    sections.push(createPhoneSection("tels.work", appConfig.tels.work))
                }
                if (appConfig.tels.home !== undefined) {
                    sections.push(createPhoneSection("tels.home", appConfig.tels.home))
                }
            }

            if (appConfig.emails !== undefined) {
                if (appConfig.emails.general !== undefined) {
                    sections.push(createEmailSectionNode("emails.general", appConfig.emails.general))
                }
                if (appConfig.emails.home !== undefined) {
                    sections.push(createEmailSectionNode("emails.home", appConfig.emails.home))
                }
                if (appConfig.emails.work !== undefined) {
                    sections.push(createEmailSectionNode("emails.work", appConfig.emails.work))
                }
            }

            if (appConfig.url !== undefined) {
                sections.push(createLinkSectionNode("url", appConfig.url))
            }

            const nodeAddress = renderAddresses();
            if (nodeAddress !== null) {
                sections.push(nodeAddress);
            }

            // Last note
            if (appConfig.note !== undefined) {
                sections.push(createSectionNode("note", appConfig.note))
            }

            // Download button
            // sections.push(
            //     <Grid item sx={{width: '100%', textAlign: "center"}}>
            //         <Button variant={"contained"} disableElevation sx={{backgroundColor: "#6192BD"}}>Download VCard</Button>
            //     </Grid>
            //         )
        }

        return sections;
    }

    const renderVcard = () => {

        // render specific parts
        const renderAvatar = () => {

            const avatarStyles = {
                avatarWithInitials: {width: 100, height: 100, fontSize: 50, bgcolor: '#6192BD'},
                avatarWithIcon: {width: 100, height: 100, fontSize: 60, bgcolor: '#fff'},
                icon: {fontSize: 60}
            }

            if (appConfig !== null) {
                if (!!appConfig.image) return <Avatar sx={avatarStyles.avatarWithIcon} src={appConfig.image}/>
                else if (!!appConfig.first_name && !!appConfig.last_name) {
                    return <Avatar sx={avatarStyles.avatarWithInitials} children={`${appConfig?.first_name[0]}${appConfig?.last_name[0]}`}/>
                } else if (!!appConfig.first_name && appConfig.last_name === undefined) {
                    return <Avatar sx={avatarStyles.avatarWithInitials} children={`${appConfig?.first_name[0]}`}/>
                }
                // Default
                return <Avatar sx={avatarStyles.avatarWithIcon}><PersonIcon sx={avatarStyles.icon}/></Avatar>
            }
        }

        const renderName = () => {

            if (appConfig !== null) {
                return (
                    <Typography sx={styles.name}>
                        {!!appConfig.first_name && appConfig.first_name} {!!appConfig.last_name && appConfig.last_name}
                    </Typography>
                )
            }
            return <></>
        }

        const renderJob = () => {

            if (!!appConfig) {
                return (
                    <Typography sx={styles.job}>
                        {!!appConfig.job_title && appConfig.job_title}
                        {(!!appConfig.job_title && !!appConfig.org) && <>&nbsp;-&nbsp;</>}
                        {!!appConfig.org && appConfig.org}
                    </Typography>
                )
            }

            return <></>
        }

        const renderActions = () => {

            if (appConfig !== null) {

                // Count the number of items in order to find the size of the items
                // in the bar
                let actionsCount = 0
                if (appConfig.tels !== undefined) {
                    actionsCount += 1
                }
                if (appConfig.emails !== undefined) {
                    actionsCount += 1
                }
                if (appConfig.addrs !== undefined) {
                    actionsCount += 1
                }
                if (appConfig.url !== undefined) {
                    actionsCount += 1
                }

                if (actionsCount === 0) {
                    return null
                }

                // Divide the max size of an item in a Grid by the number of items detected
                const sizeItem = 12 / actionsCount

                return (
                    <Grid container spacing={3}>
                        {appConfig.tels !== undefined ?
                            <Grid md={sizeItem} item sx={{textAlign: "center"}}>
                                <ButtonBase>
                                    <Grid container direction={"column"}>
                                        <Grid item>
                                            <PhoneIcon sx={{color: "#525252"}}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{fontSize: 10}}>
                                                {t('call')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ButtonBase>
                            </Grid>
                            :
                            null
                        }

                        {appConfig.emails !== undefined ?
                            <Grid md={sizeItem} item sx={{textAlign: "center"}}>
                                <ButtonBase>
                                    <Grid container direction={"column"}>
                                        <Grid item>
                                            <EmailIcon sx={{color: "#525252"}}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{fontSize: 10}}>
                                                {t('email')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ButtonBase>
                            </Grid>
                            :
                            null
                        }

                        {/*{appConfig.addr !== undefined && appConfig.city !== undefined ?*/}
                        {/*    <Grid md={3} item sx={{textAlign: "center"}}>*/}
                        {/*        <ButtonBase href={"https://www.google.com/maps/search/?api=1&query=24%20Crescent%20Road%2CBrentwood%2CCM145JR%2CGB"}>*/}
                        {/*            <Grid container direction={"column"}>*/}
                        {/*                <Grid item>*/}
                        {/*                    <DirectionsIcon sx={{color: "#525252"}}/>*/}
                        {/*                </Grid>*/}
                        {/*                <Grid item>*/}
                        {/*                    <Typography sx={{fontSize: 10}}>*/}
                        {/*                        Map*/}
                        {/*                    </Typography>*/}
                        {/*                </Grid>*/}
                        {/*            </Grid>*/}
                        {/*        </ButtonBase>*/}
                        {/*    </Grid>*/}
                        {/*    :*/}
                        {/*    null*/}
                        {/*}*/}

                        {appConfig.url !== undefined ?
                            <Grid md={3} item sx={{textAlign: "center"}}>
                                <ButtonBase>
                                    <Grid container direction={"column"}>
                                        <Grid item>
                                            <WebIcon sx={{color: "#525252"}}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{fontSize: 10}}>
                                                {t('website')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ButtonBase>
                            </Grid>
                            :
                            null
                        }
                    </Grid>
                )
            }

            return null
        }

        // main render logic
        if (loading) {
            return (
                <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                    sx={styles.loaderWrapper}
                >
                    <CircularProgress size={75}/>
                </Grid>
            )
        } else {
            return (
                <Container maxWidth={"xs"} sx={styles.vcardContainer}>
                    {/* Avatar */}
                    <Grid container direction="column" alignItems="center" justifyContent="center">
                        <Grid item>
                            {renderAvatar()}
                        </Grid>
                        <Grid item sx={styles.nameWrapper}>
                            {renderName()}
                        </Grid>
                        <Grid item sx={styles.jobWrapper}>
                            {renderJob()}
                        </Grid>
                        {/*TODO(vincent): Reimplement actions*/}
                        {/*<Grid item>*/}
                        {/*    {renderActions()}*/}
                        {/*</Grid>*/}
                    </Grid>

                    <br/>

                    <Grid
                        container
                        justifyContent="flex-start"
                        alignItems={"center"}
                        spacing={3}
                        textAlign={"left"}
                    >
                        {renderVcardContent()}
                    </Grid>

                    <Button
                        variant={'contained'}
                        startIcon={<DownloadIcon/>}
                        onClick={download}
                        sx={styles.downloadButton}
                    >
                        {t('add_contact')}
                    </Button>
                </Container>
            )
        }
    }

    return (
        <div className="App">{renderVcard()}</div>
    );
}

export default App;
