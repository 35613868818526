import React from 'react';
import {Paper, Typography} from "@mui/material";

export default function PaperWithLabel(props: any) {

    const ref = React.useRef(null);
    const {children, label} = props;

    return (
        <Paper
            ref={ref}
            elevation={0}
            square={false}
            variant="outlined"
            style={{
                position: "relative"
            }}
        >
            <Typography style={{
                fontWeight: "300",
                position: "absolute",
                paddingLeft: '3px',
                paddingRight: '3px',
                top: '-12px',
                left: '15px',
                backgroundColor: "white"
            }}>
                {label}
            </Typography>

            <div style={{
                padding: '16px'
            }}>
                {children}
            </div>
        </Paper>
    )

}